import { TestBoardStateCode } from '@/includes/types/Board.types'
import { boardStateInfo } from '@/components/HelpMessages/TestStatusHelpInfo/Board'
import { channelStateInfo } from '@/components/HelpMessages/TestStatusHelpInfo/Channel'
import { ChannelsApi } from '@/includes/Api/Channels.api'
import { errorNotification } from '@/includes/services/NotificationService'
import { SlimChannelInfo, TestChannelStateCode } from '@/includes/types/Channel.types'

import Component from 'vue-class-component'
import Vue from 'vue'
import { snakeCase } from 'lodash'
import { Emit } from 'vue-property-decorator'
import { isAxiosError } from "axios";

type BoardEntity = {
    code: TestBoardStateCode | null
    entity: 'board' | null
}

type ChannelEntity = {
    code: TestChannelStateCode | null
    entity: 'channel' | null
}

type CurrentState = BoardEntity | ChannelEntity

export type TestChannelResultPayload = {
    channel: SlimChannelInfo
    boardId: string
}

@Component
export default class TestStateMixin extends Vue {
    @Emit()
    updateChannelInfo(channel: SlimChannelInfo, boardId: string): TestChannelResultPayload {
        return { channel, boardId }
    }

    buttonDisabled = false

    isLoading = false

    isStateModalOpen = false

    state: CurrentState = {
        code: null,
        entity: null
    }

    async testChannelStatus(boardKey:string, channelId:number): Promise<void> {
        this.buttonDisabled = true

        this.isLoading = true

        try {
            const response = await ChannelsApi.testChannel('tg', {
                board_key: boardKey,
                channel_id: channelId
            })

            this.buttonDisabled = false
            this.updateChannelInfo(response.channel, boardKey)
        } catch (error: unknown) {
            if (isAxiosError(error) && error.response?.status === 429) {
                this.buttonDisabled = true

                setTimeout(() => this.buttonDisabled = false, 15000)
                errorNotification('Попробуйте проверить статус позже')
            } else {
                errorNotification(error)
                this.buttonDisabled = false
            }
        } finally {
            this.isLoading = false
        }
    }

    stateTitle(state: TestBoardStateCode | TestChannelStateCode): string {
        const snakeCaseState = snakeCase(state)

        return this.$te(snakeCaseState) ? this.$t(snakeCaseState).toString() : state.toString()
    }

    handleEntityStateClick(data: { state: CurrentState['code'], entity: CurrentState['entity'] }): void {
        const { state, entity } = data

        if (state) {
            this.state.code = state
            this.state.entity = entity

            this.isStateModalOpen = !!boardStateInfo[state] || !!channelStateInfo[state]
        }
    }

    get statusInfoData() {
        const { code, entity } = this.state

        if (code) {
            if (entity === 'board') {
                return boardStateInfo[code]
            } else {
                return channelStateInfo[code]
            }
        }
    }
}
